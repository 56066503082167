import { getIntFinQSiteUrl, getToken } from '@/utils';

export const toIntFinQ = () => {
  const url = getIntFinQSiteUrl();
  if (getToken()) {
    window.open(url);
  } else {
    window.open(`/user/login?redirect=${encodeURIComponent(url)}`);
  }
};

export const BannerData = {
  title: ['IntFinQ', '知识管理及写作助手'],
  desc: '支持多种文件格式，轻松打造个人知识库，通过知识检索、智能辅助创作，有效提升知识写作质量及效率，成为您的高效知识写作助手',
  backgroundImage: '/images/product/knowledge_base/product-pic_banner_scene@2x.png',
  btnText: '在线试用',
};

export const AdvanceData = {
  advanceList: [
    {
      icon: '/images/product/knowledge_base/icon_finance_adv_01@2x.png',
      keyword: '快',
      title: '极速问答交互体验',
      desc: '百页级文档问答平均3秒内响应，自定义文档上传百页4秒内解析完成',
    },
    {
      icon: '/images/product/knowledge_base/icon_finance_adv_02@2x.png',
      keyword: '准',
      title: '信息检索定位精准，可溯源',
      desc: '针对财报、招股书、公司公告类金融文档，单文档及跨文档检索准确率均达到行业领先，精准定位原文出处，降低信息验证成本',
    },
    {
      icon: '/images/product/knowledge_base/icon_finance_adv_03@2x.png',
      keyword: '智能',
      title: '内容写作助手',
      desc: '知识库内容素材一键引用，大模型助力内容创作',
    },
  ],
};

export const PlatformSceneData = {
  title: '产品特色功能',
  sceneList: [
    {
      name: '金融长文档阅读',
      desc: '对话式阅读体验，所问即所得，除检索结果之外提供精准原文段落，快速验证答案真实性',
      image: '/images/product/knowledge_base/feature01@2x.png',
    },
    {
      name: '跨文档信息检索',
      desc: '支持同行业多公司横向对比、多文档纵向时序发展分析，一问一答中化信息为洞察',
      image: '/images/product/knowledge_base/feature02@2x.png',
    },
    {
      name: '个人知识库管理',
      desc: '可任意上传1000份 500M以内的个人文档，支持PDF、图片等格式，运用行业领先问答检索能力，提供高效阅读体验',
      image: '/images/product/knowledge_base/feature03@2x.png',
    },
  ],
};

export const SceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/product/knowledge_base/pic_customer_bg_01@2x.png',
      name: '市场运营人员',
      desc: '定期收集、整理和分析市场信息，包括竞争对手、行业趋势、客户需求等，为公司制定相应的市场策略，包括活动策划、内容运营、SEO&SEM等。',
      cards: [
        {
          name: '内容运营人员',
          desc: ['包括文案型、产品型和资源型内容运营', '负责内容的创作、分发和消费'],
          type: '应用案例',
        },
        {
          name: 'SEO&SEM',
          desc: ['负责搜索引擎优化和搜索引擎营销', '提高网站的可见度和流量'],
          type: '应用案例',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_02@2x.png',
      name: '行业分析师',
      desc: '是否还在花很长时间查找、阅读行业调研报告、竞争对手分析、历史参考案例？我们支持快速准确的内容查询和总结，提高报告撰写的效率，确保报告内容的准确性，成为分析师的有力助手。',
      cards: [
        {
          name: '咨询/评估机构',
          desc: ['基于特定专题，通过公开信息查询、实地调研，形成深度分析报告'],
          type: '应用案例',
        },
        {
          name: '上市公司战略投资',
          desc: ['分析公司所处竞争格局，制定公司长期发展目标和战略方向'],
          type: '应用案例',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_04@2x.png',
      name: '个人创作者',
      desc: '出于个人兴趣、表达自我、建立个人品牌或实现商业变现等动机，在不同社交媒体平台，根据个人专长和受众偏好创作不同的内容。',
      cards: [
        {
          name: '个人创作者',
          desc: ['活跃在不同的平台，创作多种体裁的内容', '在市场竞争中寻找自己的定位和增长点'],
          type: '应用案例',
        },
      ],
    },
  ],
};
